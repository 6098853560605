
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const mainRules = {
    language: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    content: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    templateCode: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    msgType: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    status: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
}
export default{
    mainRules
}