<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto"  ref='form_' class="fromClass"
            :rules='rules' label-position="top">

            <el-form-item :label='$t("adminMessT.name2")' prop="language">
                <el-select v-model="fromValue.language" :placeholder="$t('inputTit.xuanze')" :teleported='false'>
                    <el-option :label="item.label" :value="item.value" v-for='item in languageArray' :key = 'item.appId'  />
                </el-select>
            </el-form-item>
            <el-form-item :label='$t("adminMessT.name3")' prop="content">
                <el-input v-model="fromValue.content" type="textarea" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label='$t("adminMessT.name4")' prop="templateCode">
                <el-select v-model="fromValue.templateCode" :placeholder="$t('inputTit.xuanze')" :teleported='false'>
                    <el-option  :label="item.label" :value="item.value" v-for='item in templateCodeArray' :key = 'item.value'  />
                </el-select>
            </el-form-item>

            <el-form-item :label='$t("adminMessT.name5")' prop="msgType" width="100%">
                <el-select v-model="fromValue.msgType" :placeholder="$t('inputTit.xuanze')" multiple :teleported='false'>
                    <el-option  :label="item.label" :value="item.value" v-for='item in msgTypeArray' :key = 'item.value'  />
                </el-select>
            </el-form-item>
            <el-row class="row-bg" justify="end">
                <el-button  @click="emit('closeFun')">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"  @click="completeFun(form_)">{{$t('button.tijiao')}}</el-button>
            </el-row>
        </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    import { getDataStyle } from '@/utils/dataBind/parmsView' 
    
    const fromValue = reactive({ 
        templateId: '',language: '',content:'',
        templateCode: '',msgType: '',status: '',tenantId:''
    })

    const form_ = ref(null)
    const rules = ref(null);
    rules.value = addRules.mainRules
    const style_ = ref(0)

    const emit = defineEmits(['successFun','closeFun'])
    const languageArray = ref([]);getDataStyle('language_type',(e)=>{languageArray.value = e})
    const templateCodeArray = ref([]);getDataStyle('send_msg_scenes',(e)=>{templateCodeArray.value = e})
    const msgTypeArray = ref([]);getDataStyle('msg_sender_type',(e)=>{msgTypeArray.value = e})
    const completeFun = (form_)=>{
        if (!form_) return
        form_.validate((e) => {
            if(e){
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.templateId
                    obj_.msgType = obj_.msgType.join(",")
                    obj_.serviceProvider = "1"
                    Api_.addMessTemplateList(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    Api_.upateMessTemplateList(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj)=>{//初始化数据
        style_.value = e;
        if(obj){
            for(let index in fromValue){
                fromValue[index] = obj[index]
            }
            fromValue.msgType = fromValue.msgType.split(',')
            fromValue.status = fromValue.status.toString()
        }else{
            for(let index in fromValue){
                fromValue[index] = ''
            }
        }
    }
    defineExpose({init_})
</script>
<style lang="scss" scoped>
    .el-dialog__body{
        padding-top: 0;
    }
</style>